import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {MainRoutingModule} from './main-routing.module';
import {AppComponent} from './presentation/components/app/app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatDividerModule} from "@angular/material/divider";
import {MatButtonModule} from "@angular/material/button";
import {MatListModule} from "@angular/material/list";
import {NotFoundComponent} from './presentation/components/not-found/not-found.component';
import {ForbiddenComponent} from './presentation/components/forbidden/forbidden.component';
import {GenericErrorComponent} from './presentation/components/generic-error/generic-error.component';
import {HttpClientModule} from "@angular/common/http";
import {LoadingComponent} from './presentation/components/loading/loading.component';
import {SplashScreenComponent} from "./presentation/components/splash-screen/splash-screen.component";


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    ForbiddenComponent,
    GenericErrorComponent,
    LoadingComponent,
    SplashScreenComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    MainRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatButtonModule,
    MatListModule,
  ],
  providers: [],
  exports: [
    ForbiddenComponent,
    NotFoundComponent,
    GenericErrorComponent,
    LoadingComponent
  ],
  bootstrap: [AppComponent]
})
export class MainModule {
}
