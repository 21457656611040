import {Injectable} from "@angular/core";
@Injectable({
  providedIn: 'root'
})
export class UserSessionService {
  constructor() {

  }

  public user?: UserModelData | null;
}

export interface UserModelData {
  cpf: string;
  name: string;
}
