<!DOCTYPE html>
<html>

<head>
  <meta charset="UTF-8">
  <title>Erro</title>
  <style>

  </style>
</head>

<body>
<div class="error-container">
  <img src="./assets/pages/error.png" alt="Erro" class="error-image">
  <p class="error-message">Oops! Algo deu errado. Por favor,<br />tente novamente mais tarde.</p>
  <!-- Alterar link conforme ambiente -->
  <a href="{{link}}" class="return-link">Ir para o VR Admissão</a>
  <br />
  <span>0.9.13</span>
</div>
</body>

</html>
