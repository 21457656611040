import { IEnvironment } from "src/app/modules/shared/interfaces/enviroment.interface";

export const environment: IEnvironment = {
  mode: 'development',
  sensediaClientId: "",
  apiURL: 'https://api.hcm.dev.uex.io',
  apiPath: 'api/vrid/v1',
  portalPath: 'api/portal/v1',
  authCookieName: 'hcm-user',
  vridLoginURL: 'https://app.hcm.dev.uex.io/#/vrid/?continue=',
  hcmUrl: 'https://app.hcm.dev.uex.io',
  refreshTokenTTL: 60*4,
  requestHeaders: {
    allowOrigin: 'https://app.hcm.dev.uex.io',
  },
  myIpConfig: {
    apiUrl: 'https://my-ip.dev.uex.io',
    token: '425a98019e3454bf4235c1c45d5cc0aa'
  }
}
