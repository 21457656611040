import {AbstractDao, RequestParams, ResourceParams} from "./abstract.dao";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../../../environments/environment";
import {Injectable} from "@angular/core";
import {AuthService} from "../../../../auth/services/auth.service";
@Injectable({
  providedIn: 'any'
})
export class BaseDao extends AbstractDao {
  constructor(private _http: HttpClient) {
    super(_http);
  }

  override url: string = `${environment.apiURL}`
  override path = '';

  override timeout = 60*1000;

  override headerInterceptor(headers: any, resource_params: ResourceParams, request_params: RequestParams): HttpHeaders {

    const tokens = AuthService.getTokens();

    if (tokens && tokens.access_token) {
      headers['Authorization'] = 'Bearer ' + tokens.access_token;
      // headers['Access-Control-Allow-Origin'] = `${environment.requestHeaders?.allowOrigin}`;
      // Adiciona o x-client-id se não for ambiente da uex
      if (`${environment.mode}` !== 'uex') {
        headers['client_id'] = `${environment.sensediaClientId}`
      }
    }

    // resource_params.withCredentials = true;

    return headers;
  }
}
