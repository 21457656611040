import { Component } from '@angular/core';
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent {
  public redirectUrl = environment.hcmUrl;
}
