import {Injectable} from "@angular/core";
import {AuthDao} from "../../auth/data/remote/dao/auth.dao";
import {AuthService} from "../../auth/services/auth.service";
import {Router} from "@angular/router";
import {RoutesService} from "./routes.service";
import {environment} from "../../../../environments/environment";
import {UserSessionService} from "../../auth/services/user-session.service";

@Injectable({ providedIn: 'any' })
export class UserInfoService {
  constructor(
    private _router: Router,
    private _authDao: AuthDao,
    private _userSessionService: UserSessionService
  ) {
  }

  public pidTimeOut?: number;

  public execute() {
      const tokens = AuthService.getTokens();

      if (tokens && tokens.access_token) {
        this._authDao.accessToken({
          access_token: tokens.access_token
        }).then((response) => {
    
          console.log(response)
          this._userSessionService.user = response.data.data;
        }).catch(async (e) => {
          this._userSessionService.user = null;
          this.clearIntervalRefreshToken();
          switch (e.status) {
            case 401:
              await this._router.navigate([RoutesService.forbidden])
              return;
            default:
              await this._router.navigate([RoutesService.internal_error])
          }
        })
      }
  }
  public clearIntervalRefreshToken() {

    if (!this.pidTimeOut) {
      return;
    }

    clearInterval(this.pidTimeOut);
  }
}
