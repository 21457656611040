<div
  class="app-splash-screen"
  *ngIf="showSplash" [@fadeInOut]
>
  <div class="app-splash-inner">
    <div class="app-logo"></div>
    <div class="app-label">Aguarde</div>
    <div class="app-loader"></div>
  </div>
</div>
