import {Injectable} from "@angular/core";

@Injectable({ providedIn: 'root' })
export class CookieService {

  /**
   * Retorna o objeto do cookie
   * @param name
   */
  public static getCookie(name: string): CookieInfo | null {

    const cookies = document.cookie.split(';');

    const cookie = cookies.find((cookieStr) => {
      const [cookieName] = cookieStr.split('=');
      return cookieName.trim() === name;
    });

    if (cookie) {

      const [cookieName, cookieValue] = cookie.split('=');
      const decodedValue = decodeURIComponent(cookieValue);

      const cookieInfo: CookieInfo = {
        name: cookieName.trim(),
        value: decodedValue,
        domain: '',
        expires: null,
      };

      const cookieDomain = cookie
        .split(';')
        .find((part) => part.trim().startsWith('domain='));

      if (cookieDomain) {
        cookieInfo.domain = cookieDomain.split('=')[1].trim();
      } else {
        cookieInfo.domain = window.location.hostname;
      }

      // Parsing the expiration date, if available
      const expirationString = cookies.find((part) => part.trim().startsWith(`${name}=`));
      if (expirationString) {
        const expirationDate = expirationString
          .split(';')
          .find((part) => part.trim().startsWith('expires='));

        if (expirationDate) {
          const expirationDateString = expirationDate.split('=')[1].trim();
          const expirationDateTimestamp = Date.parse(expirationDateString);
          if (!isNaN(expirationDateTimestamp)) {
            cookieInfo.expires = new Date(expirationDateTimestamp);
          }
        }
      }

      return cookieInfo;
    }

    return null;
  }
}


interface CookieInfo {
  name: string;
  value: string | any;
  domain: string;
  expires: Date | null;
}


