import {HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpParams, HttpRequest} from "@angular/common/http";
import {Subscription} from "rxjs";
import { timeout } from 'rxjs/operators';
import * as _ from 'lodash';
import {Injectable} from "@angular/core";


Injectable({
  providedIn: 'any'
})
export class AbstractDao {
  constructor(
    private _hppClient: HttpClient
  ) {

  }
  public url: string = '';

  public path = '';

  public timeout = 30000;

  public buildResource(resource_params: ResourceParams): Resource {

    // Define os valores padrão para o ResourceParams
    resource_params = this._setResourceParamsDefaults(resource_params);

    // Declara o Resource que será retornado
    const resource: Resource = (request_params: RequestParams = {}): ResourcePromise => {

      let request: HttpRequest<{}>;
      let request_subscription: Subscription;
      let reject_promise: (response: ResourceResponse) => any;

      const resource_path: ResourcePath = this.getPath(resource_params);

      // Inicializa o cacheamento para essa requisição
      if (resource_params.method != RequestMethod.Get) {
        resource_params.cache = undefined;
      }

      const child_name: string = _.snakeCase(this.constructor.name.replace('Model', ''));
      const schema_name: string = resource_params.cache ? `${child_name}_${resource_params.cache.name}` : '';

      const logout_on_unauthorized: boolean = this._getLogoutOnUnauthorized(resource_params, request_params);
      const cache_info: CacheInfo = {
        enabled: !!resource_params.cache,
        replace: true,
        name: schema_name,
        params: request_params
      };

      // Restorna uma Promise
      const promise = new Promise<ResourceResponse>((resolve, reject) => {
        reject_promise = reject;

        const request_url: string = this.url + this.parseResourcePath(resource_path, request_params);
        const request_options: RequestOptions = this.getOptions(resource_params, request_params);
        const request_timeout = resource_params.timeout ? resource_params.timeout : this.timeout;

        const has_body = this._hasBody(resource_params.method);
        if (has_body) {
          const request_body: any = request_options.params;
          request_options.params = undefined;
          request = new HttpRequest(resource_params.method, request_url, request_body, request_options);
        } else {
          request = new HttpRequest(resource_params.method, request_url, request_options);
        }

        request_subscription = this._hppClient.request(request)
          .pipe(timeout(request_timeout))
          .subscribe({
          next: (response)=>
        {
          if (response.type == HttpEventType.Response) {
            resolve(this._handleSuccess(response, cache_info, resource_params.responseInterceptor));
          }
        }
      ,
        error: (err) => reject(this._handleError(err, cache_info, logout_on_unauthorized, resource_params.responseInterceptor))
      });

      });

      const response = {
        then: (on_success: ResourceResponsePromise, on_error?: ResourceResponsePromise) => {
          promise.then(on_success, on_error);
          return response;
        },
        catch: (on_error: ResourceResponsePromise) => {
          promise.catch(on_error);
          return response;
        },
        finally: (on_finally: () => void) => {
          promise.then(on_finally, on_finally);
          return response;
        },
        abort: () => {
          if (request_subscription && !request_subscription.closed) {
            request_subscription.unsubscribe();
            reject_promise(this._handleError({ name: 'Cancelled' }, cache_info, logout_on_unauthorized, resource_params.responseInterceptor));
          }
        }
      };

      return response;
    };

    return resource;
  }

  public getPath(resource_params: ResourceParams): ResourcePath {
    const _path = `${this.path ? this.path : ''}${resource_params.path ? resource_params.path : ''}`;
    const _keys = _path ? _path.match(/\{([^\{\}]*)\}/g) : [];
    const resource_path: ResourcePath = {
      template: _path ? _path : '',
      keys: _keys ? _keys : []
    };

    resource_path.keys.forEach((key, index) => {
      resource_path.template = resource_path.template.replace(key, '{$' + (index + 1) + '}');
      resource_path.keys[index] = key.replace(/[\{\}]/g, '');
    });

    return resource_path;
  }

  public parseResourcePath(resource_path: ResourcePath, request_params: RequestParams): string {
    let path: string = resource_path.template;
    resource_path.keys.forEach((key, index) => {
      const is_optional = key[0] == '?' || key[1] == '?';
      const ignore_from_payload = key[0] == '!' || key[1] == '!';
      const clean_key = (is_optional || ignore_from_payload) ? key.replace(/[\?\!]/g, '') : key;
      const value = this._findParamsKey(request_params, clean_key.split('.'), ignore_from_payload);
      const path_key = (value == '' && is_optional && path.indexOf('/{$' + (index + 1) + '}') > -1) ? '/{$' + (index + 1) + '}' : '{$' + (index + 1) + '}';
      path = path.replace(path_key, value);
    });
    return path;
  }

  public headerInterceptor(headers: any, resource_params: ResourceParams, request_params: RequestParams): string | { [key: string]: any } {
    return headers;
  }

  public clearSession() {}

  private getOptions(resource_params: ResourceParams, request_params: RequestParams): RequestOptions {
    const request_options: RequestOptions = {
      headers: this._getHeaders(resource_params, request_params),
      params: <HttpParams>this._getParams(resource_params, request_params),
      withCredentials: resource_params.withCredentials,
      responseType: 'json',
      reportProgress: false
    };

    if (_.isFunction(resource_params.requestInterceptor)) {
      return resource_params.requestInterceptor(request_options);
    }

    return request_options;
  }

  private _getHeaders(resource_params: ResourceParams, request_params: RequestParams): HttpHeaders {
    const headers: { [key: string]: string } = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    };

    if (_.isFunction(this.headerInterceptor)) {
      return new HttpHeaders(this.headerInterceptor(headers, resource_params, request_params));
    }

    return new HttpHeaders(headers);
  }

  private _getLogoutOnUnauthorized(resource_params: ResourceParams, request_params: RequestParams): boolean {
    let logout_on_unauthorized = true;

    if (!_.isEmpty(resource_params) && _.isBoolean(resource_params.logoutOnUnauthorized)) {
      logout_on_unauthorized = resource_params.logoutOnUnauthorized;
    } else if (!_.isEmpty(request_params) && _.isBoolean(request_params.logoutOnUnauthorized)) {
      logout_on_unauthorized = request_params.logoutOnUnauthorized;
    }

    return logout_on_unauthorized;
  }

  private _getParams(resource_params: ResourceParams, request_params: RequestParams): HttpParams | RequestParams {
    if (this._hasBody(resource_params.method)) {
      return request_params;
    } else {
      const params = {};
      this._setParams(params, request_params);
      const http_params = new HttpParams({ fromObject: params });
      return http_params;
    }
  }

  private _hasBody(method?: RequestMethod): boolean {

    if (!method) {
      return false;
    }

    return ['POST', 'PUT', 'PATCH'].indexOf(method) > -1;
  }

  private _setParams(params: any, request_params: any, prefix?: string): boolean {
    let count = 0;

    if (_.isPlainObject(request_params)) {
      _.each(request_params, (item, key) => {
        if (_.isEmpty(item) && !_.isBoolean(item) && !_.isNumber(item)) { return; }

        const param_prefix: any = prefix ? `${prefix}[${key}]` : key;

        if (_.isPlainObject(item) || _.isArray(item)) {
          if (this._setParams(params, item, param_prefix)) { count++; }
        } else {
          params[param_prefix] = item;
          count++;
        }
      });
    } else if (_.isArray(request_params) && prefix) {
      let index = 0;
      request_params.forEach((item) => {
        if (_.isEmpty(item) && !_.isBoolean(item) && !_.isNumber(item)) { return; }
        const param_prefix: any = `${prefix}[${index}]`;

        if (_.isPlainObject(item) || _.isArray(item)) {
          if (this._setParams(params, item, param_prefix)) {
            count++;
            return;
          }
        } else {
          params[param_prefix] = item;
          count++;
        }

        index++;
      });
    }

    return count > 0;
  }

  private _findParamsKey(params: RequestParams, key_arr: Array<string>, ignore_from_payload: boolean): string {
    let value = '';

    if (params instanceof Object) {
      if (key_arr.length > 1) {
        value = this._findParamsKey(params[key_arr[0]], key_arr.splice(1), ignore_from_payload);
      } else if (params[key_arr[0]] || _.isNumber(params[key_arr[0]])) {
        value = params[key_arr[0]];
        if (ignore_from_payload) {
          delete params[key_arr[0]];
        }
      }
    }

    return (value || _.isNumber(value)) ? `${value}` : '';
  }

  private _setResourceParamsDefaults(resource_params: ResourceParams): ResourceParams {
    return Object.assign({
      method: RequestMethod.Get,
      path: '',
      requestInterceptor: null,
      responseInterceptor: null,
      withCredentials: false,
      logoutOnUnauthorized: true
    }, resource_params);
  }

  private _handleSuccess(response: any, cache_info: CacheInfo, responseInterceptor?: ResponseInterceptor): ResourceResponse {
    let response_data: ResourceResponse;

    if (!cache_info.enabled || (cache_info.enabled && cache_info.replace)) {
      response_data = {
        data: response.body,
        cached: false,
        status: response.status,
        statusText: response.statusText
      };
    } else {
      response_data = response;
      response_data.cached = true;
    }

    if (_.isFunction(responseInterceptor)) {
      return responseInterceptor(response_data);
    }

    return response_data;
  }

  private _handleError(err: HttpErrorResponse | any, cache_info: CacheInfo, logout_on_unauthorized: boolean, responseInterceptor?: ResponseInterceptor): ResourceResponse {
    let response_data: ResourceResponse;

    if (err.status == 401 && logout_on_unauthorized) {
      this.clearSession();
    } else if (err.status == 403) {

    }

    if (cache_info && (!cache_info.enabled || (cache_info.enabled && cache_info.replace))) {
      response_data = {
        data: err.error || null,
        cached: false,
        status: err.status,
        statusText: err.statusText
      };

      if (err.name == 'TimeoutError') {
        response_data.status = 504;
        response_data.statusText = 'TimeoutError';
      }
    } else {
      response_data = {
        data: null,
        cached: !!cache_info,
        status: 0,
        statusText: err.message || err.name
      };
    }

    if (_.isFunction(responseInterceptor)) {
      return responseInterceptor(response_data);
    }

    return response_data;
  }

}

export enum RequestMethod {
  Post = 'POST',
  Put = 'PUT',
  Path = 'PATH',
  Get = 'GET',
  Delete = 'DELETE',
  Head = 'HEAD',
  Options = 'OPTIONS',
  Jsonp = 'JSONP'
}

export type RequestMethodWithBody = 'DELETE' | 'GET' | 'HEAD' | 'JSONP' | 'OPTIONS';
export type RequestMethodWithoutBody = 'POST' | 'PUT' | 'PATH';

export interface RequestOptions {
  headers?: HttpHeaders;
  reportProgress?: boolean;
  params?: HttpParams;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
}

export type Resource = (request_params?: RequestParams, ...args: any[]) => ResourcePromise;

export interface ResourcePromise {
  then: (on_success: ResourceResponsePromise, on_error?: ResourceResponsePromise) => ResourcePromise;
  catch: (on_error: ResourceResponsePromise) => ResourcePromise;
  finally: (on_finally: () => void) => ResourcePromise;
  abort: () => void;
}

export type ResourceResponsePromise = (response: ResourceResponse) => void;

export interface ResourceParams {
  method: RequestMethod;
  path?: string;
  requestInterceptor?: RequestInterceptor;
  responseInterceptor?: ResponseInterceptor;
  withCredentials?: boolean;
  logoutOnUnauthorized?: boolean;
  timeout?: number;
  cache?: CacheSettings;
}

export interface CacheSettings {
  name: string;
  ignore_params?: boolean;
}

export interface ResourcePath {
  template: string;
  keys: Array<string>;
}

export interface CacheInfo {
  enabled: boolean;
  replace: boolean;
  name: string;
  params: Object;
}

export interface RequestParams {
  logoutOnUnauthorized?: boolean;
  [key: string]: any;
}

export type RequestInterceptor = (requestData: RequestOptions) => RequestOptions;

export type ResponseInterceptor = (response: ResourceResponse) => ResourceResponse;

export interface ResourceResponse {
  data: any;
  cached: boolean;
  status: number;
  statusText: string;
}

export interface DefaultRequestData {
  [key: string]: any;
}

export interface DefaultResponseData {
  error?: string;
  success?: string;
  id?: number;
}
