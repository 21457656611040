import {BaseDao} from "../../../../core/data/remote/dao/base.dao";
import {RequestMethod, Resource} from "../../../../core/data/remote/dao/abstract.dao";
import {Injectable} from "@angular/core";
import {environment} from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'any'
})
export class AuthDao extends BaseDao {

  /**
   *  Faz o refresh token
   */
  public refreshToken: Resource = this.buildResource({
    method: RequestMethod.Post,
    path: `/${environment.apiPath}/auth/refresh`,
  });

  public accessToken: Resource = this.buildResource({
    method: RequestMethod.Post,
    path: `/${environment.apiPath}/auth-token`,
  });
}
