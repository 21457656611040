import { Component } from '@angular/core';
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  public redirectUrl = environment.hcmUrl;
}
