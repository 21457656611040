import {Component, Input} from '@angular/core';
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss']
})
export class GenericErrorComponent {
  @Input()
  public title = 'OPS!';

  @Input()
  public headerTwo = 'Formato nao suportado';

  @Input()
  public linkText = 'Se acredita que é um erro por favor contate nosso suporte';

  @Input()
  public link =  environment.hcmUrl;


  @Input()
  public buttonText = 'Ir para VR Admissão';

}
