<!DOCTYPE html>
<html>

<head>
  <meta charset="UTF-8">
  <title>Erro 403 - Acesso negado</title>
  <style>
    body {
      /* Cor de fundo (igual a do PDF) */
      background-color: #4d525d;
      font-family: Arial, sans-serif;
      text-align: center;
      margin: 0;
      padding: 0;
    }

    .error-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      border-radius: 10px;
      padding: 40px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
    }

    .error-image {
      height: 300px;
      margin: 0 auto;
      display: block;
    }

    .error-message {
      font-size: 24px;
      margin-top: 40px;
    }

    .return-link {
      /* Cor para o link de retorno - Cor VR */
      color: #00B11D;
      text-decoration: none;
      font-size: 18px;
      display: block;
      margin-top: 20px;
    }

    .return-link:hover {
      text-decoration: underline;
    }
  </style>
</head>

<body>
<div class="error-container">
  <img src="./assets/pages/error_403.png" alt="Erro 403" class="error-image">
  <p class="error-message">Oops! Acesso negado. Parece que você não<br />tem permissão para visualizar esta página.
  </p>
  <!-- Alterar link conforme ambiente -->
  <a href="{{redirectUrl}}" class="return-link">Ir para o VR Gente</a>
</div>
</body>

</html>
