import {EventEmitter, Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class SplashScreenService {
  public onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
}
