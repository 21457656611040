import { inject, NgModule } from '@angular/core';
import { CanMatchFn, Route, UrlSegment, Routes, RouterModule } from '@angular/router';
import { AuthService } from '../auth/services/auth.service';
import { ForbiddenComponent } from './presentation/components/forbidden/forbidden.component';
import { GenericErrorComponent } from './presentation/components/generic-error/generic-error.component';
import { NotFoundComponent } from './presentation/components/not-found/not-found.component';
import { SplashScreenService } from './services/splash-screen.service';


const hasPermission: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
  return inject(AuthService).execute(segments);
};

const openSplashScreen: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
  inject(SplashScreenService).onOpen.emit(true);
  return true
};

const closeSplashScreen = () => {
  inject(SplashScreenService).onClose.emit(true);
  return true;
};

const routes: Routes = [
  {
    path: 'holerites',
    title: 'GED',
    canActivate: [openSplashScreen],
    canMatch: [hasPermission],
    loadChildren: () => import('../payslip/payslip.module').then(m => m.PayslipModule)
  },
  {
    path: 'holerite-unidades',
    title: 'GED',
    canActivate: [openSplashScreen],
    canMatch: [hasPermission],
    loadChildren: () => import('../payslip-unit/payslip-unit.module').then(m => m.PayslipUnitModule)
  },
  {
    path: 'termos',
    title: 'GED',
    canActivate: [openSplashScreen],
    canMatch: [hasPermission],
    loadChildren: () => import('../therm/therm.module').then(m => m.ThermModule)
  },
  {
    path: 'documentos',
    title: 'GED',
    canActivate: [openSplashScreen],
    canMatch: [hasPermission],
    loadChildren: () => import('../document/document.module').then(m => m.DocumentModule)
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
    canActivate: [closeSplashScreen]
  },
  {
    path: 'error',
    component: GenericErrorComponent,
    canActivate: [closeSplashScreen]
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    canActivate: [closeSplashScreen],
  },
  {
    path: '',
    component: NotFoundComponent,
    canActivate: [closeSplashScreen]
  },
  {
    path: '**',
    component: NotFoundComponent,
    canActivate: [closeSplashScreen]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {
}

