import {Injectable} from "@angular/core";
import {CookieService} from "../../core/services/cookie.service";
import {environment} from "../../../../environments/environment";
import {ActivatedRoute, Router, UrlSegment} from "@angular/router";
import {Uuidv4Service} from "../../core/services/uuidv4.service";
import {RoutesService} from "../../main/services/routes.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private _router: Router,
              private _activatedRoute: ActivatedRoute
  ) {
  }

  public async execute(segments: UrlSegment[]): Promise<boolean> {
    if (!segments || !segments[1]) {
      await this._router.navigate([RoutesService.page_404]);
      return false;
    }

    const uuid = segments[1]?.path ?? '';

    const cookie = CookieService.getCookie(`${environment.authCookieName}`);

    if (!cookie) {

      if (!uuid) {
        await this._router.navigate([RoutesService.page_404]);
        return false;
      }



      window.location.href  = `${environment.vridLoginURL}` + encodeURIComponent(window.location.href.toString());
      return false;
    }

    if (segments[0]?.path == 'termos') {
      return true
    }

    if (!Uuidv4Service.isValidUuidV4(uuid)) {
      await this._router.navigate([RoutesService.page_404]);
      return false;
    }

    return true;
  }

  public static getTokens ():ObjectToken | null | undefined {
    const cookieInfo = CookieService.getCookie(`${environment.authCookieName}`);

    if (cookieInfo?.value) {
      const tokens: ObjectToken = JSON.parse(decodeURIComponent(cookieInfo.value));
      return  tokens;
    }

    return  null;
  }
}

export interface ObjectToken {
  access_token: string;
  refresh_token: string;
}
