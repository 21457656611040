import {Component, OnInit} from '@angular/core';
import { UserInfoService } from '../../../services/user-info.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private _userInfoService: UserInfoService
  ) {

  }

  public ngOnInit() {
    this.startServices()
  }

  public startServices() {
    /**
     * Inicializa a atualização do refresh token
     */
    this._userInfoService.execute();
  }
}
