import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {animate, AnimationTriggerMetadata, style, transition, trigger} from "@angular/animations";
import {SplashScreenService} from "../../../services/splash-screen.service";

@Component({
  selector: "splash-screen",
  templateUrl: "./splash-screen.component.html",
  styleUrls: ["./splash-screen.component.scss"],
  animations: [FadeInOut(0, 600, true)],
  encapsulation: ViewEncapsulation.None
})
export class SplashScreenComponent implements OnInit {


  constructor(
    private _splashScreenService: SplashScreenService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    this._splashScreenService.onOpen.subscribe(() => {
      setTimeout( () => {
        this.showSplash = true;
        this._changeDetectorRef.detectChanges();
      }, 600)

    });

    this._splashScreenService.onClose.subscribe(() => {
      setTimeout( () => {
        this.showSplash = false;
        this._changeDetectorRef.detectChanges();
      }, 600)
    })
  }

  public windowWidth: string = window.innerWidth + "px";

  public showSplash = true;

  ngOnInit(): void {
    setTimeout(() => {
      this.windowWidth = "-" + window.innerWidth + "px";
    }, 300);
  }
}

export function FadeInOut(timingIn: number, timingOut: number, height: boolean = false): AnimationTriggerMetadata  {
  return trigger('fadeInOut', [
    transition(':enter', [
      style(height ? { opacity: 0 , height: 0, } : { opacity: 0, }),
      animate(timingIn, style(height ? { opacity: 1, height: 'fit-content' } : { opacity: 1, })),
    ]),
    transition(':leave', [
      animate( timingOut, style(height ? { opacity: 0, height: 0, } : { opacity: 0, })),
    ])
  ]);
}
