import {Injectable} from "@angular/core";

@Injectable()
export class Uuidv4Service {
  /**
   * Valida se um hash é valido
   * @param str: string
   */
  public static isValidUuidV4(str?: string | undefined | null): boolean {

    if (!str) {
      return false;
    }

    const uuidv4Pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidv4Pattern.test(str);
  }
}
